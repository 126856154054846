<template>
  <div class="rate-actions">
    <span :class="`rate-actions__title mr-8`">{{ $t('COMMON.RATE_PARSING_RESULT') }}</span>
    <span>
      <v-tooltip bottom transition="none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-large
            class="rate-actions__like"
            v-bind="attrs"
            v-on="on"
            @click="setRate(10)"
          >
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>
        </template>
        {{ $t('COMMON.BUTTON.LIKE') }}
      </v-tooltip>
    </span>
    <span>
      <v-tooltip bottom transition="none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-large
            class="rate-actions__dislike"
            v-bind="attrs"
            v-on="on" 
            @click="setRate(0)"
          >
            <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
        </template>
        {{ $t('COMMON.BUTTON.DISLIKE') }}
      </v-tooltip>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    rateCvClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    setRate(value) {
      this.$emit('setRate', value, true)
    },
  },
}
</script>

<style lang="scss">
.rate-actions,
.rate-actions__title {
  display: flex;
  align-items: center;
}


.rate-actions__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: $color-dark-blue;
}

.rate-actions-dialog {
  .rate-actions__title {
    display: none;
  }
}

.rate-actions__like.theme--light.v-btn.v-btn--icon,
.rate-actions__dislike.theme--light.v-btn.v-btn--icon {
  height: 75px;
  width: 75px;

 .v-icon {
    font-size: 45px;
  }
}

.rate-actions-dialog {
  .rate-actions__like.theme--light.v-btn.v-btn--icon,
  .rate-actions__dislike.theme--light.v-btn.v-btn--icon {
    height: 65px;
    width: 65px;

    .v-icon {
      font-size: 35px;
    }
  }
}

.rate-actions__like.theme--light.v-btn.v-btn--icon {
  color: $color-dark-blue;

  &:hover {
    color: $color-dark-blue-hover;
  }
}

.rate-actions__dislike.theme--light.v-btn.v-btn--icon {
  color: $color-pink;

  &:hover {
    color: $color-pink-hover;
  }
}
</style>
